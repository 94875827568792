<template>
  <WeContainer card="">
    <div class="card">
      <div class="card-header pt-4 pb-4 custom-header-bg">
        <h5 class="font-weight-bold mb-0 text-danger">
          <span class="text-uppercase">SİLİNEN YORUMLAR</span>
        </h5>
      </div>
      <div class="card-body position-relative" style="min-height: 200px">
        <WeTable
          v-if="ready"
          v-bind:index="false"
          v-bind:data.sync="comment.list"
          v-bind:columns="columns"
          v-bind:actions="tableActions"
          v-on:on-action="onAction"
        ></WeTable>
        <WeLoading v-else />
      </div>
    </div>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Trash",
  data() {
    return {
      ready: false,
      tableActions: [
        {
          icon: "fas fa-sync fa-sm",
          class: "btn-outline-primary",
          action: "restore",
          tooltip: "Geri Al",
        },
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger ml-2",
          action: "remove",
          tooltip: "Kalıcı Olarak Sil",
        },
      ],
      columns: [
        { name: "date", th: "Tarih", type: "datetime", width: "15%" },
        { name: "member", th: "Müşteri Adı Soyadı", type: "string" },
        { name: "email", th: "E-Posta Adresi", type: "string" },
        { name: "product_name", th: "Ürün Adı", type: "string" },
        { name: "title", th: "Yorum Başlığı", type: "string" },
        { name: "score", th: "Puan", type: "star" },
        { name: "is_active", th: "Onay Durumu", type: "boolean_disabled" },
      ],
    };
  },
  methods: {
    ...mapActions("comment", ["getList", "restore", "remove"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onRemove(data.row, data.index);
      } else if (data.key == "restore") {
        this.onRestore(data.row, data.index);
      }
    },
    onRestore(row, index) {
      let message = `${row.title} başlıklı yorumu geri almak istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Geri Al",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.restore({
            id: row.id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Yorum Başarıyla Geri Alındı");
                this.comment.list.splice(index, 1);
              } else {
                this.$swal({
                  title: "Yorum Geri Alınamadı",
                  text: result.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        }
      });
    },
    onRemove(row, index) {
      let message = `${row.title} başlıklı yorumu kalıcı olarak silmek istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        text: "Bu işlem geri alınamaz.",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.remove({
            id: row.id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Yorum Başarıyla Silindi");
                this.comment.list.splice(index, 1);
              } else {
                this.$swal({
                  title: "Yorum Silinemedi",
                  text: result.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        }
      });
    },
    getAll() {
      this.ready = false;
      this.getList({
        id: this.$route.params.id,
        deleted: true,
        onSuccess: () => {
          this.ready = true;
        },
      });
    },
  },
  computed: {
    ...mapState(["comment", "shared"]),
  },
  mounted() {
    this.getAll();
  },
};
</script>
